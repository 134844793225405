<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-btn
            @click="loadMore"
            absolute
            mr-0
            class=""
            outlined
            color="primary"
            style="position: absolute; top: 10px; right: 10px;"
            >Get More Activity Data</v-btn
          >
          <v-card-title>Activity</v-card-title>
          <v-card-subtitle>User Actions and Event History</v-card-subtitle>
          <!--  <vue-json-to-csv :json-data="activityCSV" :csv-title="'activity'">
            <v-btn color="red" class="ma-6" outlined>
              Download
              <v-icon>mdi-excel</v-icon>
            </v-btn>
          </vue-json-to-csv> -->
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="activities"
              class="elevation-1"
              :footer-props="{ 'items-per-page-options': [20, 50, 100, -1] }"
            >
              <template v-slot:item.createdOn="{ item }">
                {{ friendlyDate(item.createdOn) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios");
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
import VueJsonToCsv from "vue-json-to-csv";
export default {
  name: "Activities",
  components: {
    VueJsonToCsv
  },
  data: () => ({
    activities: [],
    headers: [
      { text: "Date", value: "createdOn" },
      {
        text: "User",
        value: "fullname"
      },
      { text: "Action", value: "activity" }
    ],
    activityCSV: []
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    }
    // compActivities() {
    //   // // const data = this.activities.map(a => a.fullname = a.firstname + ' ' + a.lastname);
    //   // let data = this.activities.forEach(a => a.fullname = a.firstname + ' ' + a.lastname)
    //   // return data
    //   const data = this.activites;
    //   for (var i = data.length - 1; i >= 0; i--) {
    //     data[i].fullname = data[i].firstname + ' ' + data[i].lastname
    //   }
    //   return data
    // }
  },
  methods: {
    friendlyDate(d) {
      return helpers.formatActivityDateTime(d);
    },
    loadMore() {
      let vm = this;
      // query api for users
      axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
      axios
        .get(vm.$root.urls.api + "/activities/5000", {})
        .then(function(results) {
          let data = results.data;
          for (var i = data.length - 1; i >= 0; i--) {
            data[i].fullname = `${data[i].firstname} ${data[i].lastname}`;
          }
          vm.activities = data;
        });
    }
  },
  mounted: function() {
    let vm = this;
    // query api for users
    axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
    axios.get(vm.$root.urls.api + "/activities", {}).then(function(results) {
      let data = results.data;
      for (var i = data.length - 1; i >= 0; i--) {
        data[i].fullname = `${data[i].firstname} ${data[i].lastname}`;
      }
      vm.activities = data;
    });
  }
};
</script>
